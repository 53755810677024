// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-index-js": () => import("/Users/jan/personal/janandolivia/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-rsvp-js": () => import("/Users/jan/personal/janandolivia/src/pages/rsvp.js" /* webpackChunkName: "component---src-pages-rsvp-js" */),
  "component---src-pages-styleguide-js": () => import("/Users/jan/personal/janandolivia/src/pages/styleguide.js" /* webpackChunkName: "component---src-pages-styleguide-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/Users/jan/personal/janandolivia/.cache/data.json")

